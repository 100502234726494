// ==========================================================================
// Slider Set Royal Slider JS Module
// ==========================================================================

define(['jquery', 'module'], function($) {

  // Global
  // ==========================================================================

  var module = arguments[arguments.length - 1];
  var rsInstance = [];
  var touchDevice = !!('ontouchstart' in document.documentElement);
  var $sliderSet = typeof module.config().rsSliders !== 'undefined' && module.config().rsSliders.length > 1 ? $(module.config().rsSliders) : $('.slider-set .royal-slider');
  var rsConfigs = {
    autoHeight: typeof module.config().rs_autoHeight === 'undefined' ? false : module.config().rs_autoHeight,
    allowCSS3: typeof module.config().rs_allowCSS3 === 'undefined' ? false : module.config().rs_allowCSS3,
    autoScaleSlider: typeof module.config().rs_autoScaleSlider === 'undefined' ? false : module.config().rs_autoScaleSlider,
    autoPlay: {
      enabled: typeof module.config().rs_auto_play_enabled === 'undefined' ? true : module.config().rs_auto_play_enabled,
      pauseOnHover: typeof module.config().rs_pauseOnHover === 'undefined' ? true : module.config().rs_pauseOnHover,
      delay: module.config().rs_delay || 4000
    },
    arrowsNav: typeof module.config().rs_arrowsNav === 'undefined' ? true : module.config().rs_arrowsNav,
    arrowsNavAutoHide: typeof module.config().rs_arrowsNavAutoHide === 'undefined' ? true : module.config().rs_arrowsNavAutoHide,
    controlNavigation: module.config().rs_controlNavigation || 'bullets',
    controlsInside: typeof module.config().rs_controlsInside === 'undefined' ? true : module.config().rs_controlsInside,
    fadeinLoadedSlide: module.config().rs_fadeinLoadedSlide || touchDevice,
    globalCaption: typeof module.config().rs_globalCaption === 'undefined' ? false : module.config().rs_globalCaption,
    imageAlignCenter: typeof module.config().rs_imageAlignCenter === 'undefined' ? false : module.config().rs_imageAlignCenter,
    imageScaleMode: module.config().rs_imageScaleMode || 'fill',
    keyboardNavEnabled: typeof module.config().rs_keyboardNavEnabled === 'undefined' ? false : module.config().rs_keyboardNavEnabled,
    loop: typeof module.config().rs_loop === 'undefined' ? true : module.config().rs_loop,
    navigateByClick: typeof module.config().rs_navigateByClick === 'undefined' ? false : module.config().rs_navigateByClick,
    numImagesToPreload: module.config().rs_numImagesToPreload || 6,
    orientation: module.config().rs_orientation || 'horizontal',
    slidesSpacing: module.config().rs_slidesSpacing || 0,
    sliderDrag: module.config().rs_sliderDrag || touchDevice,
    sliderTouch: module.config().rs_sliderTouch || touchDevice,
    startSlideId: module.config().rs_startSlideId || 0,
    thumbsFitInViewport: typeof module.config().rs_thumbsFitInViewport === 'undefined' ? false : module.config().rs_thumbsFitInViewport,
    transitionType: module.config().rs_transitionType || touchDevice === true ? 'move' : 'fade'
  };

  var createSlider = function(i, that, theConfigs) {

    var rs = $(that).royalSlider($.extend({}, theConfigs));
    rsInstance[i] = {
      'find': $(this),
      'data': rs.data('royalSlider')
    };
  };

  $sliderSet.each(function(i) {

    if ($(this)[0].parentNode.classList.contains('slider-set-static')) {
      rsConfigs.autoPlay.enabled = false;
    }

    $(this).children().length > 1 ? createSlider(i, $(this), rsConfigs) : $(this).parent().addClass('static');
    $(this).find('.img-block-container').show();
  });

  $sliderSet.find('.btn[data-href]').click(function() {
    var location = $(this).attr('data-href');
    window.location = location;
    return false;
  });
  return rsInstance;

});